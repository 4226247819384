<template>
  <div id="new-feature-bagde" @click="goToNewFeatures">
    <Icon tooltip="Conheça área de novas aplicações">
      <NewFeatureIcon class="icon" />
    </Icon>
    <div v-if="showNewFeature" class="unread-indicator"></div>

    <!-- <NewFeaturesInitialModal @updateShowModal="updateShowModal" /> -->
  </div>
</template>

<script>
import Cookies from 'js-cookie'
export default {
  components: {
    Icon: () => import('@/components/General/Icon'),
    NewFeatureIcon: () => import('@/assets/icons/novas-funcionalidades.svg')
    // NewFeaturesInitialModal: () =>
    //   import('@/modules/newFeatures/modals/NewFeaturesInitialModal')
  },
  data() {
    return {
      showNewFeature: true
    }
  },
  computed: {
    checkCookie() {
      const showModalCookie = Cookies.get('showNewFeatureModal')
      return showModalCookie === undefined ? true : showModalCookie === 'true'
    }
  },
  mounted() {
    this.showNewFeature = this.checkCookie
  },
  methods: {
    updateShowModal() {
      this.showNewFeature = false
    },
    goToNewFeatures() {
      this.showNewFeature = false
      this.$router.push('/apps')
    }
  }
}
</script>

<style lang="scss">
#new-feature-bagde {
  z-index: 10;
  position: relative;
  .icon {
    width: 24px;
    fill: rgb(127, 145, 210);
  }
  .title__xxl {
    color: var(--blue-500);
  }
}
</style>
